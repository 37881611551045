<template>
  <div
    v-if="video"
    class="row"
  >
    <div class="col px-0 text-center">
      <h3 class="font-weight-bold mt-2 w-75 mx-auto">
        {{ video.title }}
      </h3>
      <!-- eslint-disable-next-line -->
      <div class="video-embed col-12 col-md-10 mx-auto px-0 mt-1" v-html="video.embed"></div>
      <!-- eslint-disable-next-line -->
      <h5 class="w-75 mx-auto mt-3" v-html="video.description" />
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
    }
  },
  computed: {
    video() {
      return this.$store.getters['videos/selectedVideo']
    },
  },
  created() {
    this.$store.dispatch('videos/fetchVideoById', this.$route.params.id)
  },
  methods: {
  },
}
</script>
